<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    title="ADD SCHEDULE"

    size="xlg"
    hide-footer
    @hidden="closeModal"
  >
    <div>
      <div class="d-flex justify-content-start w-25">
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="bg-primary text-white"
              style="width: 100px"
            >Name
            </b-input-group-text>
          </template>
          <b-form-input

            v-model="data.name"
          />

        </b-input-group>
      </div>

      <div class="mt-3">
        <b-table-simple
          ref="schedule"
        >

          <b-thead>
            <b-tr>
              <b-th />
              <b-th class="text-center">
                SUN
              </b-th>
              <b-th class="text-center">
                MON
              </b-th>

              <b-th class="text-center">
                TUE
              </b-th>

              <b-th class=" text-center">
                WED
              </b-th>

              <b-th class=" text-center">
                THU
              </b-th>

              <b-th class="text-center">
                FRI
              </b-th>

              <b-th class=" text-center">
                SAT
              </b-th>

            </b-tr>
          </b-thead>
          <b-tbody>

            <b-tr>
              <b-td>FROM</b-td>
              <b-td>

                <v-select

                  v-model="schedule.sun_from"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_sunday"
                  @input="changehour(1)"
                />

              </b-td>
              <b-td>

                <v-select

                  v-model="schedule.mon_from"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_monday"
                  @input="changehour(3)"
                />

              </b-td>
              <b-td>

                <v-select

                  v-model="schedule.tue_from"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_tuesday"
                  @input="changehour(5)"
                />

              </b-td>
              <b-td>

                <v-select

                  v-model="schedule.wed_from"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_wednesday"
                  @input="changehour(7)"
                />

              </b-td>
              <b-td>

                <v-select

                  v-model="schedule.thu_from"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_thursday"
                  @input="changehour(9)"
                />

              </b-td>
              <b-td>

                <v-select

                  v-model="schedule.fri_from"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_friday"
                  @input="changehour(11)"
                />

              </b-td>
              <b-td>

                <v-select

                  v-model="schedule.sat_from"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_saturday"
                  @input="changehour(13)"
                />

              </b-td>

            </b-tr>
            <b-tr>
              <b-td>TO</b-td>
              <b-td>

                <v-select

                  v-model="schedule.sun_to"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_sunday"
                  @input="changehour(2)"
                />

              </b-td>
              <b-td>

                <v-select

                  v-model="schedule.mon_to"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_monday"
                  @input="changehour(4)"
                />

              </b-td>
              <b-td>

                <v-select

                  v-model="schedule.tue_to"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_tuesday"
                  @input="changehour(6)"
                />

              </b-td>
              <b-td>

                <v-select

                  v-model="schedule.wed_to"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_wednesday"
                  @input="changehour(8)"
                />

              </b-td>
              <b-td>

                <v-select

                  v-model="schedule.thu_to"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_thursday"
                  @input="changehour(10)"
                />

              </b-td>
              <b-td>

                <v-select

                  v-model="schedule.fri_to"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_friday"
                  @input="changehour(12)"
                />

              </b-td>
              <b-td>

                <v-select

                  v-model="schedule.sat_to"
                  label="hour"
                  :reduce="val => val.hour"
                  :options="quendo"
                  :disabled="schedule.close_saturday"
                  @input="changehour(13)"
                />

              </b-td>

            </b-tr>
            <b-tr>
              <b-td>Remote</b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.remote_sunday"
                  :disabled="schedule.close_sunday"
                  @change="changehourCheck(1)"
                />
              </b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.remote_monday"
                  :disabled="schedule.close_monday"
                  @change="changehourCheck(2)"
                />
              </b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.remote_tuesday"
                  :disabled="schedule.close_tuesday"
                  @change="changehourCheck(3)"
                />
              </b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.remote_wednesday"
                  :disabled="schedule.close_wednesday"
                  @change="changehourCheck(4)"
                />
              </b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.remote_thursday"
                  :disabled="schedule.close_thursday"
                  @change="changehourCheck(5)"
                />
              </b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.remote_friday"
                  :disabled="schedule.close_friday"
                  @change="changehourCheck(6)"
                />
              </b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.remote_saturday"
                  :disabled="schedule.close_saturday"
                  @change="changehourCheck(7)"
                />
              </b-td>

            </b-tr>
            <b-tr>
              <b-td>Close</b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.close_sunday"
                  @change="changehourCheckClose(1)"
                />

              </b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.close_monday"
                  @change="changehourCheckClose(2)"
                />

              </b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.close_tuesday"
                  @change="changehourCheckClose(3)"
                />
              </b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.close_wednesday"
                  @change="changehourCheckClose(4)"
                />

              </b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.close_thursday"
                  @change="changehourCheckClose(5)"
                />

              </b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.close_friday"
                  @change="changehourCheckClose(6)"
                />

              </b-td>
              <b-td>

                <b-form-checkbox
                  v-model="schedule.close_saturday"
                  @change="changehourCheckClose(7)"
                />

              </b-td>

            </b-tr>
          </b-tbody>

        </b-table-simple>

      </div>
    </div>
  </b-modal>
</template>

<script>
import EmployeesService from '@/views/management/views/employees/employees.service'
import { mapGetters } from 'vuex'

import vSelect from 'vue-select'
import schedule
from '@/views/management/views/employees/employees-module/employees-list/modals/options/schedule/Schedule'
import { initialize } from '@/service/general'

export default {
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      schedule: [],
      ownControl: false,
      quendo: [{ hour: '06:00' }, { hour: '06:30' }, { hour: '07:00' }, { hour: '07:30' }, { hour: '08:00' }, { hour: '08:30' }, { hour: '09:00' }, { hour: '09:30' }, { hour: '10:00' }, { hour: '10:30' }, { hour: '11:00' }, { hour: '11:30' },
        { hour: '12:00' }, { hour: '12:30' }, { hour: '13:00' }, { hour: '13:30' }, { hour: '14:00' }, { hour: '14:30' }, { hour: '15:00' }, { hour: '15:30' }, { hour: '16:00' }, { hour: '16:30' }, { hour: '17:00' }, { hour: '17:30' },
        { hour: '18:00' }, { hour: '18:30' }, { hour: '19:00' }, { hour: '19:30' }, { hour: '20:00' }, { hour: '20:30' }, { hour: '21:00' }, { hour: '21:30' }, { hour: '22:00' }, { hour: '22:30' }, { hour: '23:00' }, { hour: '23:30' }],

      cont: 1,
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async created() {
    this.ownControl = true

    await this.getSchedDepartment()
  },

  methods: {

    async inicialize() {
      this.schedule.remote_friday = false
      this.schedule.remote_monday = false
      this.schedule.remote_saturday = false
      this.schedule.remote_sunday = false
      this.schedule.remote_thursday = false
      this.schedule.remote_tuesday = false
      this.schedule.remote_wednesday = false
      this.schedule.close_sunday = false
      this.schedule.close_monday = false
      this.schedule.close_tuesday = false
      this.schedule.close_wednesday = false
      this.schedule.close_thursday = false
      this.schedule.close_friday = false
      this.schedule.close_saturday = false
      this.schedule.sun_from = ''
      this.schedule.mon_from = ''
      this.schedule.tue_from = ''
      this.schedule.wed_from = ''
      this.schedule.thu_from = ''

      this.schedule.fri_from = ''
      this.schedule.sat_from = ''
      this.schedule.sun_to = ''
      this.schedule.mon_to = ''
      this.schedule.tue_to = ''
      this.schedule.wed_to = ''
      this.schedule.thu_to = ''
      this.schedule.fri_to = ''
      this.schedule.sat_to = ''
    },
    closeModal() {
      this.$emit('close')
    },

    async getSchedDepartment() {
      try {
        const params = {
          id: this.data.id,

        }
        const data = await EmployeesService.getScheduleDepartment(params)
        if (data.data[0]) {
          this.schedule = data.data[0]
          if (this.schedule) {
            this.schedule.remote_friday == 1 ? this.schedule.remote_friday = true : this.schedule.remote_friday = false
            this.schedule.remote_monday == 1 ? this.schedule.remote_monday = true : this.schedule.remote_monday = false
            this.schedule.remote_saturday == 1 ? this.schedule.remote_saturday = true : this.schedule.remote_saturday = false
            this.schedule.remote_sunday == 1 ? this.schedule.remote_sunday = true : this.schedule.remote_sunday = false
            this.schedule.remote_thursday == 1 ? this.schedule.remote_thursday = true : this.schedule.remote_thursday = false
            this.schedule.remote_tuesday == 1 ? this.schedule.remote_tuesday = true : this.schedule.remote_tuesday = false
            this.schedule.remote_wednesday == 1 ? this.schedule.remote_wednesday = true : this.schedule.remote_wednesday = false

            this.schedule.close_sunday == 1 ? this.schedule.close_sunday = true : this.schedule.close_sunday = false
            this.schedule.close_monday == 1 ? this.schedule.close_monday = true : this.schedule.close_monday = false
            this.schedule.close_tuesday == 1 ? this.schedule.close_tuesday = true : this.schedule.close_tuesday = false
            this.schedule.close_wednesday == 1 ? this.schedule.close_wednesday = true : this.schedule.close_wednesday = false
            this.schedule.close_thursday == 1 ? this.schedule.close_thursday = true : this.schedule.close_thursday = false
            this.schedule.close_friday == 1 ? this.schedule.close_friday = true : this.schedule.close_friday = false
            this.schedule.close_saturday == 1 ? this.schedule.close_saturday = true : this.schedule.close_saturday = false
          }
        } else {
          await this.inicialize()
        }

        return this.schedule
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    async updateSched(dato, valor) {
      try {
        const params = {
          dato,
          valor,
          id: this.data.id,
          userid: this.currentUser.user_id,
        }
        const data = await EmployeesService.updateScheduleDepartment(params)
        if (data.status === 200) {
          this.showToast('success', 'top-right', 'Update Schedule!', 'Check')
        }
      } catch (e) {
        this.showToast('danger', 'top-right', 'Oops!', 'XIcon', 'Schedule')
        return []
      }
    },

    async updateSchedCheck(dato, valor) {
      try {
        valor === true ? valor = 1 : valor = 0
        const params = {
          dato,
          valor,
          id: this.data.id,
          userid: this.currentUser.user_id,
        }
        const data = await EmployeesService.updateScheduleDepartmentCheck(params)
        if (data.status === 200) {
          this.showToast('success', 'top-right', 'Update Schedule!', 'Check')
        }
      } catch (e) {
        this.showToast('danger', 'top-right', 'Oops!', 'XIcon', 'Schedule')
        return []
      }
    },

    async updateSchedCheckClose(dato, valor) {
      try {
        valor === true ? valor = 1 : valor = 0
        const params = {
          dato,
          valor,
          id: this.data.id,
          userid: this.currentUser.user_id,
        }
        const data = await EmployeesService.updateScheduleDepartmentCheckClose(params)
        if (data.status === 200) {
          await this.getSchedDepartment()
          this.showToast('success', 'top-right', 'Update Schedule!', 'Check')
        }
      } catch (e) {
        this.showToast('danger', 'top-right', 'Oops!', 'XIcon', 'Schedule')
        return []
      }
    },
    async changehour(dato) {
      switch (dato) {
        case 1:
          await this.updateSched(dato, this.schedule.sun_from)
          break
        case 3:
          await this.updateSched(dato, this.schedule.mon_from)
          break
        case 5:
          await this.updateSched(dato, this.schedule.tue_from)
          break
        case 7:
          await this.updateSched(dato, this.schedule.wed_from)
          break
        case 9:
          await this.updateSched(dato, this.schedule.thu_from)
          break
        case 11:
          await this.updateSched(dato, this.schedule.fri_from)
          break
        case 13:
          await this.updateSched(dato, this.schedule.sat_from)
          break

        case 2:
          await this.updateSched(dato, this.schedule.sun_to)
          break
        case 4:
          await this.updateSched(dato, this.schedule.mon_to)
          break
        case 6:
          await this.updateSched(dato, this.schedule.tue_to)
          break
        case 8:
          await this.updateSched(dato, this.schedule.wed_to)
          break
        case 10:
          await this.updateSched(dato, this.schedule.thu_to)
          break
        case 12:
          await this.updateSched(dato, this.schedule.fri_to)
          break
        case 14:
          await this.updateSched(dato, this.schedule.sat_to)
          break
      }
    },
    async changehourCheck(dato) {
      switch (dato) {
        case 1:
          await this.updateSchedCheck(dato, this.schedule.remote_sunday)
          break
        case 2:
          await this.updateSchedCheck(dato, this.schedule.remote_monday)
          break
        case 3:
          await this.updateSchedCheck(dato, this.schedule.remote_tuesday)
          break
        case 4:
          await this.updateSchedCheck(dato, this.schedule.remote_wednesday)
          break
        case 5:
          await this.updateSchedCheck(dato, this.schedule.remote_thursday)
          break
        case 6:
          await this.updateSchedCheck(dato, this.schedule.remote_friday)
          break
        case 7:
          await this.updateSchedCheck(dato, this.schedule.remote_saturday)
          break
      }
    },

    async changehourCheckClose(dato) {
      switch (dato) {
        case 1:
          await this.updateSchedCheckClose(dato, this.schedule.close_sunday)
          break
        case 2:
          await this.updateSchedCheckClose(dato, this.schedule.close_monday)
          break
        case 3:
          await this.updateSchedCheckClose(dato, this.schedule.close_tuesday)
          break
        case 4:
          await this.updateSchedCheckClose(dato, this.schedule.close_wednesday)
          break
        case 5:
          await this.updateSchedCheckClose(dato, this.schedule.close_thursday)
          break
        case 6:
          await this.updateSchedCheckClose(dato, this.schedule.close_friday)
          break
        case 7:
          await this.updateSchedCheckClose(dato, this.schedule.close_saturday)
          break
      }
    },
  },

}
</script>

<style scoped>

</style>
