<template>
  <div>
    <b-card
        no-body
        class="mb-0"
    >
      <create-department
          v-if="createModalDepartment"
          :edit="edit"
          :data="departmentData"
          @refresh="refresh"
          @close="closeCreateDepartment"
      >

      </create-department>
      <create-roles
          v-if="createModalRole"
          @close="closeCreateRole"
      >

      </create-roles>

      <create-schedules
          v-if="scheduleModal"
          :data="scheduleData"
          @close="closeSchedulesModal">

      </create-schedules>
      <div class="d-flex justify-content-end">
        <b-button class="mr-2" variant="success" @click="openCreateDepartment">CREATE</b-button>
        <b-button variant="info" @click="openCreateRole">ROLES</b-button>
      </div>
      <filter-slot


          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reset-all-filters="resetAllFilters"
          @reload="$refs['departmentList'].refresh()"
      >

        <b-table
            id="departmentList"
            slot="table"
            ref="departmentList"
            show-empty
            sticky-header="50vh"
            no-provider-filtering
            :busy.sync="isBusy"
            :items="search"
            :fields="filteredFields"
            :per-page="paginate.perPage"
            :current-page="paginate.currentPage"
            :filter="filter"

            responsive="sm"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1"/>
              <strong>Loading ...</strong>
            </div>
          </template>
          <template v-slot:cell(code)="data">

            <div
                class="d-flex flex-column  justify-content-center align-items-start "


            >
              <span>{{ data.item.code }}</span>


            </div>

          </template>
          <template v-slot:cell(name)="data">

            <div
                class="d-flex flex-column  justify-content-center align-items-start "


            >
            <span>
              {{ data.item.name }}
            </span>


            </div>

          </template>
          <template v-slot:cell(supervisor)="data">

            <div
                class="d-flex flex-column  justify-content-center align-items-start "

                v-for="(roles, index) in JSON.parse(data.item.supervisor)"
                :key="index"

            >

              <ul class="m-0">
                <li>
                  {{ roles }}
                </li>


              </ul>

            </div>

          </template>
          <template v-slot:cell(roles)="data">

            <div
                class="d-flex flex-column  justify-content-center align-items-start "
                v-for="(roles, index) in JSON.parse(data.item.roles)"
                :key="index"


            >
              <ul class="m-0">
                <li>
                  {{ roles.name }}
                </li>


              </ul>
            </div>

          </template>

          <template v-slot:cell(creation_date)="data">

            <div
                class="d-flex flex-column  justify-content-center align-items-start "


            >
            <span>
             {{ data.item.created_at | myGlobal }}
            </span>


            </div>

          </template>
          <template v-slot:cell(schedules)="data">

            <div
                class="d-flex flex-column  justify-content-center align-items-start cursor-pointer "


            >
              <feather-icon class="text-primary" @click="openScheduleModal(data.item)" size="20"
                            icon="CalendarIcon"></feather-icon>


            </div>

          </template>
          <template v-slot:cell(actions)="data">

            <div
                class="d-flex   justify-content-start align-items-start "


            >
              <feather-icon class="cursor-pointer text-warning" @click="openEditDepartment(data.item)" size="20"
                            icon="EditIcon"></feather-icon>
              <feather-icon class="cursor-pointer ml-1 text-danger" @click="openDeleteDepartment(data.item)" size="20"
                            v-if="data.item.delete===2"
                            icon="Trash2Icon"></feather-icon>

            </div>

          </template>


        </b-table>

      </filter-slot>

    </b-card>

  </div>
</template>

<script>
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot";
import dataFields from "@/views/management/views/employees/employees-module/departments-list/fields.data";
import dataFilters from "@/views/management/views/employees/employees-module/departments-list/filters.data";
import EmployeesService from "@/views/management/views/employees/employees.service";
import CreateDepartment
  from "@/views/management/views/employees/employees-module/departments-list/modals/create-department/CreateDepartment";
import CreateRoles
  from "@/views/management/views/employees/employees-module/departments-list/modals/create-roles/CreateRoles";
import CreateSchedules
  from "@/views/management/views/employees/employees-module/departments-list/modals/create-schedules/CreateSchedules";
import MigrationsService from "@/views/social-network/views/migrations/migrations.service";


export default {
  components: {
    FilterSlot,
    CreateDepartment,
    CreateRoles,
    CreateSchedules
  },

  data() {
    return {
      fields: dataFields,
      totalRows: 0,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      basicSearch: true,
      filter: dataFilters,
      startPage: null,
      toPage: null,
      items: [],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      department: null,
      createModalDepartment: false,
      createModalRole: false,
      edit: false,
      departmentData: {},
      scheduleModal: false,
      scheduleData: []
    }
  },

  computed: {
    filteredFields() {
      return this.fields
    },

  },



  methods: {
    openScheduleModal(data) {
      this.scheduleModal = true
      this.scheduleData = data
    },
    closeSchedulesModal() {
      this.scheduleModal = false
    },
    openCreateDepartment() {
      this.createModalDepartment = true
      this.edit = false
    },
    openEditDepartment(data) {
      this.createModalDepartment = true
      this.departmentData = data
      this.edit = true
    },

    async openDeleteDepartment(data) {
      const params = {
        id: data.id

      }
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          await EmployeesService.deleteDepartment(params)

          this.$refs.departmentList.refresh()

          this.showSuccessSwal('Department has been deleted successfully')
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    openCreateRole() {
      this.createModalRole = true
    },
    closeCreateDepartment() {
      this.createModalDepartment = false
    },
    closeCreateRole() {
      this.createModalRole = false
    },

    resetAllFilters() {
      this.filter.forEach(filter => {
        filter.model = null
      })
      this.filterPrincipal.model = null
      this.$refs.departmentList.refresh()
    },
    resetSearch() {
      this.searchInput = ''
      this.$refs.departmentList.refresh()
    },

    refresh() {
      this.$refs.departmentList.refresh()
    },
    async search(ctx) {
      try {

        const params = {
          perPage: this.paginate.perPage,
          page: ctx.currentPage,

          text: this.filterPrincipal.model,
          from: this.filter[0].model,
          to: this.filter[1].model,

        }

        const data = await EmployeesService.getDepartments(params)

        // eslint-disable-next-line no-param-reassign

        this.items = data.data.data
        // Must return an array of items or an empty array if an error occurred

        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.data.last_page
        this.totalData = data.data.total
        this.totalRows = data.data.total
        this.toPage = data.data.to

        return this.items
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },


  },
}
</script>

<style scoped>

</style>
