<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    title="ROLES"
    hide-footer
    size="xmd"

    @hidden="closeModal"
  >
    <div>
      <create-new-role
        v-if="newRoleModal"
        :edit="edit"
        :data="infoRole"
        @getRoles="refresh"
        @close="closeNewRole"
      />
      <div class="d-flex justify-content-start mb-1">
        <b-button
          class="mr-2"
          variant="success"
          @click="openNewRole"
        >
          Create
        </b-button>

      </div>
      <b-card
        no-body
        class="mb-0"
      >

        <b-table

          ref="createRoles"
          small
          :fields="fields"
          :items="getRoles"
          class="font-small-3 text-center"
        >

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template v-slot:cell(code)="data">

            <div>
              <span> {{ data.item.code }}</span>
            </div>
          </template>

          <template v-slot:cell(rol)="data">

            <div>
              <span> {{ data.item.name }}</span>
            </div>
          </template>
          <template v-slot:cell(rol)="data">

            <div>
              <span> {{ data.item.name }}</span>
            </div>
          </template>
          <template v-slot:cell(creation_date)="data">

            <div>
              <span> {{ data.item.created_at }}</span>
            </div>
          </template>
          <template v-slot:cell(actions)="data">

            <div>
              <feather-icon
                class="mr-1 pointer text-warning cursor-pointer"
                size="20"
                icon="EditIcon"
                @click="editRole(data.item)"
              />

            </div>
          </template>
        </b-table>
      </b-card>

    </div>

  </b-modal>
</template>

<script>
import EmployeesService from '@/views/management/views/employees/employees.service'
import { mapGetters } from 'vuex'
import dataFields
from '@/views/management/views/employees/employees-module/departments-list/modals/create-roles/fields.data.js'
import CreateNewRole
from '@/views/management/views/employees/employees-module/departments-list/modals/create-roles/create-new-role/CreateNewRole'

export default {

  components: {
    CreateNewRole,

  },
  data() {
    return {
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      roleData: [],
      optionRoles: [],
      edit: false,
      spinnerOn: false,
      fields: dataFields,
      newRoleModal: false,
      infoRole: {},
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async created() {
    this.ownControl = true
  },

  methods: {
    refresh() {
      this.$refs.createRoles.refresh()
    },
    openNewRole() {
      this.newRoleModal = true
      this.edit = false
    },
    closeNewRole() {
      this.newRoleModal = false
    },
    editRole(info) {
      this.newRoleModal = true
      this.edit = true
      this.infoRole = info
    },

    closeModal() {
      this.$emit('close')
    },

    async getRoles() {
      try {
        const data = await EmployeesService.getRoles()

        // eslint-disable-next-line no-param-reassign

        this.optionRoles = data.data
        // Must return an array of items or an empty array if an error occurred

        return this.optionRoles
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },

}
</script>

<style scoped>
.prepend {
  background-color: #0090E7;
  border-radius: 5px 0 0 5px;
  color: white
}
</style>
