<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    :title="title"

    size="xmd"

    @hidden="closeModal"
  >
    <div>
      <b-row>
        <validation-observer ref="form">
          <b-col md="12">
            <b-row class="mt-2">
              <b-col md="6">
                <validation-provider
                  v-slot="{errors}"
                  name="Code"
                  rules="required"
                >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        class="bg-primary text-white"
                        style="width: 130px"
                      >Code
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-if="edit===true"
                      v-model="data.code"
                      v-mask="'###'"
                      :state="errors[0] ? false : null"

                      name="code"
                    />
                    <b-form-input
                      v-if="edit===false"
                      v-model="roleData.code"
                      v-mask="'###'"
                      :state="errors[0] ? false : null"

                      name="code"
                    />

                  </b-input-group>

                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="{errors}"
                  name="Name"
                  rules="required"
                >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        class="bg-primary text-white"
                        style="width: 130px"
                      >Name
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-if="edit===true"
                      v-model="data.name"
                      :state="errors[0] ? false : null"
                      name="name"
                    />
                    <b-form-input
                      v-if="edit===false"
                      v-model="roleData.name"
                      :state="errors[0] ? false : null"
                      name="name"
                    />
                  </b-input-group>

                </validation-provider>
              </b-col>

            </b-row>
          </b-col>

          <b-col
            md="12"
            class="mt-2"
          >
            <validation-provider
              v-slot="{errors}"
              name="Roles"
              rules="required"
            >

              <b-row class="m-0">
                <b-col
                  md="2"
                  class="p-0 "
                >
                  <div
                    class="d-flex align-items-center pl-1 prepend "
                    style="height: 40px; width: 130px"
                  >Roles
                  </div>
                </b-col>
                <b-col
                  v-if="edit===true"
                  md="1"
                >

                  <b-form-checkbox-group
                    v-model="info"

                    name="name"

                    :options="optionRoles"
                    text-field="name"
                    value-field="id"
                  />

                  <span
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    Option {{ errors[0] }}</span>
                </b-col>
                <b-col
                  v-if="edit===false"
                  md="2"
                >

                  <b-form-checkbox-group
                    v-model="roleData.role"

                    style="padding-left: 20px; max-width: 500px; "
                    name="name"

                    :options="optionRoles"
                    text-field="name"
                    value-field="id"
                  />

                  <span
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    Option {{ errors[0] }}</span>
                </b-col>
              </b-row>

            </validation-provider>

          </b-col>
        </validation-observer>
      </b-row>
    </div>
    <template #modal-footer>
      <b-button
        variant="primary"
        @click="createDepartment"
      >
        <b-spinner
          v-if="spinnerOn"
          small
        />
        SAVE
      </b-button>

    </template>
  </b-modal>
</template>

<script>
import EmployeesService from '@/views/management/views/employees/employees.service'
import { mapGetters } from 'vuex'

export default {
  props: {
    edit: {
      type: Boolean,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },

  },
  data() {
    return {
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      roleData: [],
      optionRoles: [],
      info: [],
      spinnerOn: false,
      title: '',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async created() {
    this.ownControl = true
    this.putTitle()
    await this.getRoles()
    await this.editData()
  },

  methods: {

    async editData() {
      if (this.edit === true) {
        if (this.data.roles_id === null) {
          this.info = []
        } else {
          this.info = JSON.parse(this.data.roles_id)
        }
      }
    },

    async createDepartment() {
      try {
        this.spinnerOn = true
        const result = await this.$refs.form.validate()
        if (result) {
          if (this.edit === false) {
            const params = {
              id: '',
              code: this.roleData.code,
              name: this.roleData.name,
              roles: this.roleData.role,
              user_id: this.currentUser.user_id,
            }

            const data = await EmployeesService.createDepartments(params)
            if (data.status === 200) {
              this.$emit('refresh')
              this.showSuccessSwal()
              this.closeModal()
            }
          } else if (this.edit === true) {
            const params = {
              id: this.data.id,
              code: this.data.code,
              name: this.data.name,
              roles: this.info,
              user_id: this.currentUser.user_id,
            }

            const data = await EmployeesService.createDepartments(params)
            if (data.status === 200) {
              this.$emit('refresh')
              this.showSuccessSwal()
              this.closeModal()
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    putTitle() {
      if (this.edit === true) {
        this.title = 'EDIT DEPARTMENT'
      } else {
        this.title = 'CREATE DEPARTMENT'
      }
    },
    closeModal() {
      this.$emit('close')
    },

    async getRoles() {
      try {
        const data = await EmployeesService.getRoles()

        // eslint-disable-next-line no-param-reassign

        this.optionRoles = data.data
        // Must return an array of items or an empty array if an error occurred

        return this.optionRoles
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },

}
</script>

<style scoped>
.prepend {
  background-color: #0090E7;
  border-radius: 5px 0 0 5px;
  color: white
}
</style>
