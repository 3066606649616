<template>
  <b-modal
      v-model="ownControl"
      scrollable
      title-class="h3 text-white font-weight-bolder"
      :title="title"

      size="lg"

      @hidden="closeModal"
  >
    <div>
      <b-row>
        <validation-observer ref="form">
          <b-col md="12">
            <b-row class="mt-2">
              <b-col md="6">
                <validation-provider v-slot="{errors}" name="Code" rules="required">
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text class="bg-primary text-white"
                                          style="width: 130px">Code
                      </b-input-group-text>
                    </template>
                    <b-form-input
                        v-if="edit===false"
                        :state="errors[0] ? false : null"
                        v-model="roleData.code"
                        name="code"
                        v-mask="'XXX'"


                    />
                    <b-form-input
                        v-if="edit===true"
                        :state="errors[0] ? false : null"
                        v-model="data.code"
                        name="code"
                        v-mask="'XXX'"


                    />

                  </b-input-group>

                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider v-slot="{errors}" name="Name" rules="required">
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text class="bg-primary text-white"
                                          style="width: 130px">Role
                      </b-input-group-text>
                    </template>
                    <b-form-input
                        v-if="edit===false"
                        :state="errors[0] ? false : null"
                        v-model="roleData.role"
                        name="name"

                    />
                    <b-form-input
                        v-if="edit===true"
                        :state="errors[0] ? false : null"
                        v-model="data.name"
                        name="name"

                    />

                  </b-input-group>

                </validation-provider>
              </b-col>
            </b-row>
          </b-col>

        </validation-observer>
      </b-row>

    </div>
    <template #modal-footer>
      <b-button
          variant="primary"
          @click="createNewRole"
      >
        <b-spinner
            v-if="spinnerOn"
            small
        />
        SAVE
      </b-button>

    </template>
  </b-modal>
</template>

<script>
import EmployeesService from "@/views/management/views/employees/employees.service";
import {mapGetters} from "vuex";

export default {
  props: {
    edit: {
      type: Boolean,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },

  },
  data() {
    return {

      ownControl: false,
      roleData: [],
      optionRoles: [],
      title: '',
      spinnerOn: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async created() {
    this.ownControl = true
    this.putTitle()

  },

  methods: {
    putTitle() {
      if (this.edit === true) {
        this.title = 'EDIT ROLE'
      } else {
        this.title = 'CREATE ROLE'
      }
    },
    async createNewRole() {
      try {
        this.spinnerOn = true
        const result = await this.$refs.form.validate()
        if (result) {

          if (this.edit === true) {
            const params = {
              id: this.data.id,
              code: this.data.code,

              role: this.data.name,
              user_id: this.currentUser.user_id,
            }
            const data = await EmployeesService.saveRoles(params)
            if (data.status === 200) {
              this.$emit('getRoles')

              this.showSuccessSwal()
              this.closeModal()


            }
          } else if (this.edit === false) {
            const params = {
              id: '',
              code: this.roleData.code,

              role: this.roleData.role,
              user_id: this.currentUser.user_id,
            }
            const data = await EmployeesService.saveRoles(params)
            if (data.status === 200) {
              this.$emit('getRoles')

              this.showSuccessSwal()
              this.closeModal()


            }
          }

        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    closeModal() {
      this.$emit('close')
    },


  }


}
</script>

<style scoped>

</style>