export default [
  {
    key: 'code',
    sortable: false,
    label: 'Code',
  },
  {
    key: 'name',
    sortable: false,
    label: 'Name',
  },
  {
    key: 'supervisor',
    sortable: false,
    label: 'Supervisor',
  },
  {
    key: 'roles',
    sortable: false,
    label: 'Roles',
  },
  {
    key: 'creation_date',
    sortable: false,
    label: 'Creation Date',
  },
  {
    key: 'schedules',
    sortable: false,
    label: 'Schedules',
  },
  {
    key: 'actions',
    sortable: false,
    label: 'Actions',
  },

]
