export default [
  {
    key: 'code',
    sortable: false,
    label: 'Code',
  },
  {
    key: 'rol',
    sortable: false,
    label: 'Rol',
  },
  {
    key: 'creation_date',
    sortable: false,
    label: 'Creation Date',
  },
  {
    key: 'actions',
    sortable: false,
    label: 'Actions',
  },

]
